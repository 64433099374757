import React, { useState, useCallback, useRef } from 'react';

interface FileDropZoneProps {
    onFileDrop: (file: File | null, errorMessage?: string) => void;
}

const FileDropZone: React.FC<FileDropZoneProps> = ({ onFileDrop }) => {
    const [isDragOver, setIsDragOver] = useState(false);
    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleDrop = useCallback((event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setIsDragOver(false);

        if (event.dataTransfer.files.length === 1) {
            const file = event.dataTransfer.files[0];
            if (file.type === 'text/plain' || file.name.toLowerCase().endsWith('.log')) {
                onFileDrop(file);
            } else {
                onFileDrop(file, 'File type: ' + file.type + ' not supported.');
            }
        } else {
            onFileDrop(null, 'Only accept 1 file.');
        }
    }, [onFileDrop]);

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setIsDragOver(true);
    };

    const handleDragLeave = () => {
        setIsDragOver(false);
    };

    const handleClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files && files.length === 1) {
            const file = files[0];
            if (file.type === 'text/plain' || file.name.toLowerCase().endsWith('.log')) {
                onFileDrop(file);
            } else {
                onFileDrop(null, 'File type not supported.');
            }
        } else {
            onFileDrop(null, 'Only accept 1 file.');
        }
    };

    return (
        <div
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onClick={handleClick}
            style={{
                border: `2px dashed ${isDragOver ? 'green' : '#ccc'}`,
                borderRadius: '4px',
                padding: '20px',
                textAlign: 'center',
                cursor: 'pointer',
            }}
        >
            <input
                type="file"
                style={{ display: 'none' }}
                ref={fileInputRef}
                onChange={handleFileInputChange}
            />
            <p>Click or drag & drop a log or txt file here</p>
        </div>
    );
};

export default FileDropZone;
