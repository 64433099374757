import React, {useRef, useState} from 'react';
import './App.css';
import FileDropZone from "./components/FileDropZone";

import LogDetails, {LogInfo} from "./components/LogDetails";

interface FileState {
    file: File | null;
}

function App() {
    const [fileState, setFileState] = useState<FileState>({file: null});
    const [logDetailsProps, setLogDetailsProps] = useState<{
        logInfos: LogInfo[];
        minDate: Date;
        maxDate: Date;
        logCategories: string[];
        loading: boolean;
        errorMessage: string;
        currentFile: string;
    }>({
        logInfos: [],
        minDate: new Date(0),
        maxDate: new Date(0),
        logCategories: [],
        loading: false,
        errorMessage: "",
        currentFile: "",
    });

    const logDetailsRef = useRef<LogDetails>(null);

    const readTextFile = (file: File): Promise<string> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (event) => {
                if (event.target) {
                    const content = event.target.result as string;
                    resolve(content);
                }
            };
            reader.onerror = () => {
                reject(new Error('Unable to read file'));
            };
            reader.readAsText(file);
        });
    };

    const parseLogInfos = (string: string): Promise<{
        logInfos: LogInfo[];
        minDate: Date;
        maxDate: Date;
        logCategories: string[];
    }> => {
        return new Promise((resolve) => {
            let regex = /\[(\d{4})\.(\d{2})\.(\d{2})-(\d{2})\.(\d{2}).(\d{2}):(\d{3})]\[([\d ]{3})](?:(\w+): )?(?:(VeryVerbose|Verbose|Log|Display|Warning|Error|Fatal): )?(?:\[([\w_:]+)] )?/gm;

            let match: RegExpExecArray | null;
            let logInfos: LogInfo[] = [];
            let index = 0;
            let frameIndexAdd = 0;
            let lastFrameIndex = -1;
            let minDate: Date | null = null, maxDate: Date = new Date(0);
            let logCategories: string[] = [];
            while ((match = regex.exec(string))) {
                let frameIndex = parseInt(match[8]);
                if (frameIndex < lastFrameIndex) {
                    frameIndexAdd++;
                }
                lastFrameIndex = frameIndex;
                let logInfo = {
                    id: index++,
                    logDateTime: new Date(parseInt(match[1]), parseInt(match[2]) - 1, parseInt(match[3]), parseInt(match[4]), parseInt(match[5]), parseInt(match[6]), parseInt(match[7])),
                    frameIndex: frameIndex + frameIndexAdd * 1000,
                    logCategory: match[9],
                    logVerbosity: match[10] ? match[10] : "Log",
                    logSource: match[11],
                    logIndex: match.index,
                    logContentIndex: match.index + match[0].length,
                    logContent: null,
                }

                if (logInfo.logDateTime && minDate === null) {
                    minDate = logInfo.logDateTime;
                }

                if (logInfo.logDateTime) {
                    maxDate = logInfo.logDateTime;
                }

                if (logInfo.logCategory && !logCategories.includes(logInfo.logCategory)) {
                    logCategories.push(logInfo.logCategory);
                }

                logCategories.sort();

                logInfos.push(logInfo);
            }

            resolve({logInfos, minDate: (minDate ? minDate : new Date(0)), maxDate, logCategories});
        });
    }

    const handleFileDrop = async (file: File | null, errorMessage?: string) => {
        if (file) {
            // Handle the dropped files
            setFileState({file: file});
            setLogDetailsProps({...logDetailsProps, loading: true, errorMessage: errorMessage ? errorMessage : ""});

            try {
                let fileString = await readTextFile(file);

                try {
                    let {logInfos, minDate, maxDate, logCategories} = await parseLogInfos(fileString);

                    for (let i = 0; i < logInfos.length; i++) {
                        if (i < logInfos.length - 1) {
                            logInfos[i].logContent = fileString.substring(logInfos[i].logContentIndex, logInfos[i + 1].logIndex);
                        } else {
                            logInfos[i].logContent = fileString.substring(logInfos[i].logContentIndex);
                        }
                    }

                    if (logInfos.length > 0) {
                        setLogDetailsProps({logInfos, minDate, maxDate, logCategories, loading: false, errorMessage: "", currentFile: file.name});
                        logDetailsRef.current?.initFilters();
                        logDetailsRef.current?.focus();
                    } else {
                        setLogDetailsProps({...logDetailsProps, loading: false, errorMessage: errorMessage ? errorMessage : "failed to parse log string. "});
                    }
                } catch (e) {
                    setLogDetailsProps({...logDetailsProps, loading: false, errorMessage: "failed to parse file string: " + e});
                }
            } catch (e) {
                setLogDetailsProps({...logDetailsProps, loading: false, errorMessage: errorMessage ? errorMessage : "failed to load file: " + e});
            }

            return;
        }

        if (errorMessage) {
            setLogDetailsProps({...logDetailsProps, loading: false, errorMessage: errorMessage});
        }
    };

    return (
        <div className="App">
            <header className="App-header">
                <p>
                    unreal engine log analyzer
                </p>
                <FileDropZone onFileDrop={handleFileDrop}/>
                {
                    fileState.file == null ?
                        <p>
                            no file selected
                        </p>
                        :
                        <p>
                            {fileState.file.name}
                        </p>
                }
                <LogDetails {...logDetailsProps} ref={logDetailsRef} />
                <a
                    href={"https://www.unrealengine.com/marketplace/en-US/profile/multiplayscape+-+plugins?count=20&sortBy=effectiveDate&sortDir=DESC&start=0"}
                    style={{
                        position: "absolute",
                        marginTop: 0,
                        alignSelf: 'flex-start',
                        color: 'white',
                    }}
                >
                    more tools created by me.
                </a>
            </header>
        </div>
    );
}

export default App;
