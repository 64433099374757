import React from 'react';
import "./LogDetals.css"

import {
    DataTable,
    DataTableFilterMeta,
    DataTableFilterMetaData,
    DataTableStateEvent
} from 'primereact/datatable';
import {
    Column, ColumnFilterElementTemplateOptions,
} from 'primereact/column';
import moment from "moment";
import {Calendar} from "primereact/calendar";
import {Button} from "primereact/button";
import {InputText} from "primereact/inputtext";
import {FilterMatchMode, FilterOperator, PrimeReactProvider} from "primereact/api";
import 'primereact/resources/themes/lara-dark-teal/theme.css';
import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css'
import {Dropdown, DropdownChangeEvent} from "primereact/dropdown";
import {Tag} from "primereact/tag";

export interface LogDetailsBackToTopButtonState {
    isAtTop: boolean;
}

class LogDetailsBackToTopButton extends React.Component<any, LogDetailsBackToTopButtonState> {
    constructor(props: any) {
        super(props);

        // Initialize the state
        this.state = {
            isAtTop: document.documentElement.scrollTop < 0.1,
        };
    }

    backToTop = () => {
        window.scrollTo({top: 0, behavior: 'smooth'});
    }

    handleScroll = () => {
        if ((document.documentElement.scrollTop < 0.1) !== this.state.isAtTop) {
            this.setState(() => {
                return {
                    isAtTop: document.documentElement.scrollTop < 0.1,
                };
            });
        }
    };

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    render() {
        return (
            <Button type="button" icon="pi pi-angle-double-up" label="Back to top" outlined
                    onClick={this.backToTop} disabled={this.state.isAtTop}/>
        );
    };
}

export interface LogInfo {
    id: number;
    logDateTime: Date | null;
    frameIndex: number | null;
    logCategory: string | null;
    logVerbosity: string | null;
    logSource: string | null;
    logIndex: number;
    logContentIndex: number;
    logContent: string | null;
}

export interface LogDetailsProps {
    logInfos: LogInfo[];
    minDate: Date;
    maxDate: Date;
    logCategories: string[];
    loading: boolean;
    errorMessage: string;
    currentFile: string;
}

export interface LogDetailsState {
    filters: DataTableFilterMeta;
    globalFilterValue: string;
}

class LogDetails extends React.Component<LogDetailsProps, LogDetailsState> {
    constructor(props: LogDetailsProps) {
        super(props);

        // Initialize the state
        this.state = {
            filters: {
                global: {
                    value: null, matchMode: FilterMatchMode.CONTAINS
                },
                logDateTime: {
                    operator: FilterOperator.AND,
                    constraints: [{value: null, matchMode: FilterMatchMode.DATE_BEFORE}],
                },
                logCategory: {
                    operator: FilterOperator.OR,
                    constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}],
                },
                logVerbosity: {
                    operator: FilterOperator.OR,
                    constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}],
                },
            },
            globalFilterValue: '',
        };
    }

    public initFilters = () => {
        this.setState(() => {
            return {
                filters: {
                    global: {
                        value: null, matchMode: FilterMatchMode.CONTAINS
                    },
                    logDateTime: {
                        operator: FilterOperator.AND,
                        constraints: [{value: null, matchMode: FilterMatchMode.DATE_BEFORE}],
                    },
                    logCategory: {
                        operator: FilterOperator.OR,
                        constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}],
                    },
                    logVerbosity: {
                        operator: FilterOperator.OR,
                        constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}],
                    },
                },
                globalFilterValue: '',
            };
        });
    };

    formatDate = (value: Date | null) => {
        return value ? moment(value).format('YYYY-MM-DD HH:mm:ss.SSS') : "";
    };

    dateBodyTemplate = (rowData: LogInfo) => {
        return this.formatDate(rowData.logDateTime);
    };

    dateFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
        return (<Calendar
            value={options.value}
            onChange={(e) => options.filterCallback(e.value, options.index)}
            showTime
            hourFormat="24"
            minDate={this.props.minDate}
            maxDate={this.props.maxDate}
            showMinMaxRange={true}
            showSeconds={true}
            showMillisec={true}
        />);
    };

    getSeverity = (verbosity: string | null) => {
        switch (verbosity) {
            case "Error":
            case "Fatal":
                return 'danger';

            case "Display":
            case "Log":
                return 'success';

            case "VeryVerbose":
            case "Verbose":
                return 'info';

            case "Warning":
                return 'warning';

            default:
                return 'info';
        }
    };

    categoryItemTemplate = (option: string) => {
        // @TODO: randomly
        return <Tag value={option} severity={'info'}/>;
    };

    categoryFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
        return <Dropdown value={options.value} options={this.props.logCategories}
                         onChange={(e: DropdownChangeEvent) => options.filterCallback(e.value, options.index)}
                         itemTemplate={this.categoryItemTemplate} placeholder="Select One" className="p-column-filter"
                         showClear/>;
    };

    verbosityBodyTemplate = (rowData: LogInfo) => {
        return <Tag value={rowData.logVerbosity} severity={this.getSeverity(rowData.logVerbosity)}/>;
    };

    verbosityItemTemplate = (option: string) => {
        return <Tag value={option} severity={this.getSeverity(option)}/>;
    };

    verbosityFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
        return <Dropdown value={options.value}
                         options={["VeryVerbose", "Verbose", "Display", "Log", "Warning", "Error", "Fatal"]}
                         onChange={(e: DropdownChangeEvent) => options.filterCallback(e.value, options.index)}
                         itemTemplate={this.verbosityItemTemplate} placeholder="Select One" className="p-column-filter"
                         showClear/>;
    };

    categoryBodyTemplate = (rowData: LogInfo) => {
        return (
            <div style={{
                overflowX: "auto",
                whiteSpace: "nowrap"
            }}>
                {rowData.logCategory}
            </div>
        );
    };

    sourceBodyTemplate = (rowData: LogInfo) => {
        return (
            <div style={{
                overflowX: "auto",
                whiteSpace: "nowrap"
            }}>
                {rowData.logSource}
            </div>
        );
    };

    contentBodyTemplate = (rowData: LogInfo) => {
        return (
            <div style={{
                overflowX: "auto",
                whiteSpace: "pre",
                wordBreak: "keep-all",
            }}>
                {rowData.logContent}
            </div>
        );
    };

    onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        let _filters = {...this.state.filters};

        (_filters['global'] as DataTableFilterMetaData).value = value;

        this.setState(() => {
            return {
                filters: _filters,
                globalFilterValue: value,
            }
        });
    };

    clearFilter = () => {
        this.initFilters();
    };

    renderHeader = () => {
        return (
            <div className="flex justify-content-between">
                <div>
                    <Button type="button" icon="pi pi-filter-slash" label="Clear" outlined onClick={this.clearFilter}
                            style={{marginRight: "10px"}}/>
                    <LogDetailsBackToTopButton/>
                </div>
                <div>
                    {this.props.errorMessage}<br/>
                    current file: {this.props.currentFile}
                </div>
                <span className="p-input-icon-left">
                    <i className="pi pi-search"/>
                    <InputText value={this.state.globalFilterValue} onChange={this.onGlobalFilterChange}
                               placeholder="Keyword Search"/>
                </span>
            </div>
        );
    };

    logDetailsContainerRef = React.createRef<HTMLDivElement>();

    focus = () => {
        this.logDetailsContainerRef.current?.focus();
        this.logDetailsContainerRef.current?.scrollIntoView({behavior: 'smooth', block: 'start'});
    }

    filterHandle = (event: DataTableStateEvent) => {
        this.setState(() => {
            return {
                filters: event.filters,
                globalFilterValue: (event.filters['global'] as DataTableFilterMetaData).value,
            };
        });
    }

    /*onFilterConstraintAdd = (event: ColumnFilterConstraintAddEvent) => {
        this.setState((prevState) => {
            let prevFilters = prevState.filters;
            (prevFilters[event.field] as DataTableOperatorFilterMetaData).constraints.push(event.constraint);
            return {
                filters: prevFilters,
                globalFilterValue: prevState.globalFilterValue,
            };
        });
    }*/

    /*onFilterConstraintRemove = (event: ColumnFilterConstraintRemoveEvent) => {
        this.setState((prevState) => {
            let prevFilters = prevState.filters;
            (prevFilters[event.field] as DataTableOperatorFilterMetaData).constraints.findIndex(event.constraint, 0);
            return {
                filters: prevFilters,
                globalFilterValue: prevState.globalFilterValue,
            };
        });
    }*/

    /*onFilterMatchModeChangeEvent = (event: ColumnFilterMatchModeChangeEvent) => {
        this.setState((prevState) => {
            let prevFilters = prevState.filters;
            (prevFilters[event.field] as DataTableOperatorFilterMetaData).constraints.push(event.constraint);
            return {
                filters: prevFilters,
                globalFilterValue: prevState.globalFilterValue,
            };
        });
    }*/

    /*onFilterOperatorChangeEvent = (event: ColumnFilterOperatorChangeEvent) => {
        this.setState((prevState) => {
            let prevFilters = prevState.filters;
            (prevFilters[event.field] as DataTableOperatorFilterMetaData).operator = event.operator;
            return {
                filters: prevFilters,
                globalFilterValue: prevState.globalFilterValue,
            };
        });
    }*/

    /*onFilterApplyClickEvent = (event: ColumnFilterApplyClickEvent) => {
        this.setState((prevState) => {
            let prevFilters = prevState.filters;
            (prevFilters[event.field] as DataTableOperatorFilterMetaData).constraints = event.constraints;
            return {
                filters: prevFilters,
                globalFilterValue: prevState.globalFilterValue,
            };
        });
    }*/

    /*onDateTimeFilterClearEvent = () => {
        this.setState((prevState) => {
            let prevFilters = prevState.filters;
            prevFilters.logDateTime = {
                operator: FilterOperator.AND,
                constraints: [{value: null, matchMode: FilterMatchMode.DATE_BEFORE}],
            };
            return {
                filters: prevFilters,
                globalFilterValue: prevState.globalFilterValue,
            };
        });
    }*/

    render() {
        return (
            <div className="LogDetailsContainer" ref={this.logDetailsContainerRef}>
                <PrimeReactProvider>
                    <DataTable value={this.props.logInfos} dataKey="id" filters={this.state.filters}
                               onFilter={this.filterHandle} globalFilterFields={["logContent"]}
                               header={this.renderHeader} stripedRows scrollable scrollHeight='flex'
                               virtualScrollerOptions={{itemSize: 40, numToleratedItems: undefined}}
                               showGridlines tableStyle={{tableLayout: 'fixed'}} loading={this.props.loading}
                               emptyMessage={"No logs"} style={{height: "100vh"}}>
                        <Column field="logDateTime" header="time" dataType="date" body={this.dateBodyTemplate} filter
                                filterField="logDateTime" filterElement={this.dateFilterTemplate}
                                filterPlaceholder="time" maxConstraints={10}
                            // onFilterConstraintAdd={this.onFilterConstraintAdd}
                            // onFilterConstraintRemove={this.onFilterConstraintRemove}
                            // onFilterMatchModeChange={this.onFilterMatchModeChangeEvent}
                            // onFilterOperatorChange={this.onFilterOperatorChangeEvent}
                            // onFilterApplyClick={this.onFilterApplyClickEvent}
                            // onFilterClear={this.onDateTimeFilterClearEvent}
                                frozen style={{width: "10rem"}}/>
                        <Column field="frameIndex" header="frame" frozen style={{width: "5rem"}}/>
                        <Column field="logCategory" header="category" body={this.categoryBodyTemplate} filter
                                filterPlaceholder="category" filterElement={this.categoryFilterTemplate}
                                maxConstraints={10} frozen style={{width: "10rem"}}/>
                        <Column field="logVerbosity" header="verbose" body={this.verbosityBodyTemplate} filter
                                filterPlaceholder="verbose" filterElement={this.verbosityFilterTemplate}
                                maxConstraints={10} frozen style={{width: "8rem"}}/>
                        <Column field="logSource" header="source" body={this.sourceBodyTemplate} maxConstraints={10}
                                frozen style={{width: "10rem"}}/>
                        <Column field="logContent" header="content" body={this.contentBodyTemplate}/>
                    </DataTable>
                </PrimeReactProvider>
            </div>
        );
    }
}

export default LogDetails;